import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import Button from "~/components/button";

const Page = () => {
  return (
    <LessonLayout
      current_step={6}
      lesson={"Lesson 4"}
      color={"hs"}
      total_count={15}
      module_title="What Could Happen?"
    >
      <ContentWrapper>
        <H2 underline>Step 6</H2>

        <div className="mt-5 w-2/3 flex content-end">
          <StaticImage
            alt="Hand using tablet."
            className="rounded"
            src="../../../images/lesson_4_step_6.jpg"
            loading="eager"
            placeholder="blurred"
            style={{
              filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
            }}
          />
        </div>

        <p>When companies advertise medicines, they need to follow certain rules set by the US Food and Drug Administration (FDA). The rules for prescription medications (medications that require a doctor’s order) are stricter than those for medicines you can buy off the shelf. This is because prescription medications are more powerful and have a higher chance of hurting someone who does not take them correctly.</p>
        <p>Click on the link to learn more about the rules for advertising prescription drugs.</p>
        <Button
          action="secondary"
          color="indigo"
          href="/prescription-drug-ad"
          icon="external"
          title="Prescription Drug Ad"
        >
          Prescription drug ad
        </Button>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

